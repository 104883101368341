import classNames from 'classnames';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import styles from './page-title.module.scss';

export interface PageTitleProps {
  header?: string;
  meta?: string;
}

export const PageTitle: FC<PageTitleProps> = (props) => {
  const { children, header, meta } = props;

  const renderTitle = () => {
    if (children) {
      return children;
    }

    return <h1>{header}</h1>;
  };

  return (
    <div id="page-title" className={classNames(styles.pageTitle)}>
      <Helmet>
        <title>{meta || header}</title>
      </Helmet>

      <div className={classNames('grid-container', styles.container)}>{renderTitle()}</div>
    </div>
  );
};
