import classNames from 'classnames';
import React, { FC, ReactElement, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { AnimationDuration } from '@stur/constants/animation.constant';
import { useEffectOnce } from '@stur/hooks/use-effect-once';
import { StyleUtils } from '@stur/utils/style-utils';

import styles from './activity-indicator.module.scss';

export type ActivityIndicatorSize = 'medium' | 'small' | 'large';

export interface ActivityIndicatorProps {
  className?: string;
  size?: ActivityIndicatorSize;
  isLoading?: boolean;
}

const Spinner = (): ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className={styles.spinner}>
    <circle cx="16" cy="16" r="14" className={styles.spinnerBackground}></circle>
    <circle cx="16" cy="16" r="14" className={styles.spinnerIndicator}></circle>
  </svg>
);

export const ActivityIndicator: FC<ActivityIndicatorProps> = (props) => {
  const { children, className, isLoading, size = 'medium' } = props;
  const [isInitialized, setIsInitialized] = useState(false);

  // enter transition won't activate is "in" is true initially
  useEffectOnce(() => setIsInitialized(true));

  return (
    <span
      className={classNames(
        styles.activityIndicator,
        { [styles.activityIndicatorLoading]: isLoading && isInitialized },
        StyleUtils.modifierClass(styles, 'activityIndicator', size),
        className
      )}
    >
      <CSSTransition
        mountOnEnter={false}
        in={isLoading && isInitialized}
        timeout={{
          enter: AnimationDuration.QUICK_ENTER + AnimationDuration.LOAD_DELAY,
        }}
        classNames={{
          enterDone: styles.spinnerEnterDone,
          enter: styles.spinnerEnter,
          enterActive: styles.spinnerEnterActive,
        }}
        unmountOnExit
      >
        <Spinner />
      </CSSTransition>
      {children && <span className={styles.contentContainer}>{children}</span>}
    </span>
  );
};
