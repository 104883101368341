import { RouteComponentProps } from '@reach/router';
import _omit from 'lodash/omit';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PageContent } from '@stur/components/layout/page-content';
import { PageLayout } from '@stur/components/layout/page-layout';
import { useConstant } from '@stur/hooks/use-constant';
import { AuthSelectors } from '@stur/store/auth/auth-selectors';
import { DOMUtils } from '@stur/utils/dom-utils';

export interface PublicRouteProps<T extends RouteComponentProps> extends RouteComponentProps {
  component: FC<T>;
}

/**
 * Wrapper for all public routes contained in src/pages
 */
export const PublicRoute = <T extends RouteComponentProps>(
  props: PublicRouteProps<T>
): ReactElement | null => {
  const { component: Component } = props;
  const componentProps = _omit(props, ['component']) as T;
  const isAuthInitialized = useSelector(AuthSelectors.isInitialized);
  const isClientOnlyPage = useConstant(() => DOMUtils.isClientOnlyPage());
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  // wait until auth status is established
  // useEffect/useState combo allows a single render cycle delay for reach
  // router update to take effect in case a navigate call was made during login
  useEffect(() => setShowPlaceholder(!isAuthInitialized), [isAuthInitialized]);

  const renderAppWrapper = () => {
    if (showPlaceholder || !isClientOnlyPage) {
      return <div className="app">{renderContent()}</div>;
    }

    // on client-only pages, the app wrapper is provided by reach router
    return renderContent();
  };

  const renderContent = () => {
    if (showPlaceholder) {
      return <PageContent isLoading={false} />;
    }

    return <Component {...componentProps} />;
  };

  return <PageLayout>{renderAppWrapper()}</PageLayout>;
};
