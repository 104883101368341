import React, { FC } from 'react';

import { ActivityIndicator } from '@stur/components/core/activity-indicator';

export interface PageLoaderProps {
  isLoading?: boolean;
}

export const PageLoader: FC<PageLoaderProps> = (props) => {
  const { children, isLoading } = props;

  if (isLoading) {
    return <ActivityIndicator size="large" isLoading={isLoading} />;
  }

  return <>{children}</>;
};
