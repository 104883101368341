import React, { VFC } from 'react';
import { Helmet } from 'react-helmet';

export const PageMeta: VFC = () => (
  <Helmet titleTemplate="%s &bull; Stur">
    <html lang="en" />
    <link rel="preconnect" href="https://fonts.gstatic.com" />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700&amp;display=swap"
      rel="stylesheet"
    />
    <title>Stur</title>
  </Helmet>
);
