/*
 * These values correspond to the values of SASS variables in _settings.scss
 */

export enum AnimationDuration {
  LONG = 375,
  ENTER = 225,
  LEAVE = 195,
  QUICK_ENTER = 165,
  QUICK_LEAVE = 135,
  LOAD_DELAY = 500,
}

export enum AnimationEase {
  STANDARD = 'cubic-bezier(0.4, 0.0, 0.2, 1)',
  OUT = 'cubic-bezier(0.0, 0.0, 0.2, 1)',
  IN = 'cubic-bezier(0.4, 0.0, 1, 1)',
  IN_OUT = 'cubic-bezier(0.4, 0.0, 0.6, 1)',
  LINEAR = 'linear',
}
