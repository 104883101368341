import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { VFC } from 'react';

import { RoutingUtils } from '@stur/utils/routing-utils';

import styles from './page-footer.module.scss';

export const PageFooter: VFC = () => {
  const year = new Date().getFullYear();

  return (
    <footer id="page-footer" className={styles.pageFooter}>
      <div className="grid-container">
        <div className="grid-x grid-padding-x align-middle">
          <div className={classNames('cell auto', styles.footerColophon)}>
            Copyright &copy; {year} Stur LLC
          </div>
          <nav className={classNames('cell shrink', styles.footerNav)}>
            <ul>
              <li>
                <Link to={RoutingUtils.routes.terms()}>Terms &amp;&nbsp;Conditions</Link>
              </li>
              <li>
                <Link to={RoutingUtils.routes.privacyPolicy()}>Privacy&nbsp;Policy</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
};
