import classNames from 'classnames';
import React, { ButtonHTMLAttributes, DetailedHTMLProps, VFC } from 'react';
import { Except } from 'type-fest';

import { Button } from '../button';
import styles from './hamburger-button.module.scss';

export interface HamburgerButtonProps
  extends Except<
    DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
    'children'
  > {
  isActive: boolean;
}
export const HamburgerButton: VFC<HamburgerButtonProps> = (props) => {
  const { className, isActive, ...rest } = props;

  return (
    <Button
      className={classNames(className, styles.hamburgerButton, { [styles.active]: isActive })}
      {...rest}
      color={undefined}
      variant="none"
    >
      <span className={styles.hamburgerIcon}>
        <span className={styles.hamburgerIconLine}></span>
        <span className={styles.hamburgerIconLine}></span>
        <span className={styles.hamburgerIconLine}></span>
      </span>
    </Button>
  );
};
