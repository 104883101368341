import { createSelector } from '@reduxjs/toolkit';

import { Breakpoint, breakpoints } from '@stur/models/breakpoint-model';
import { RootState } from '@stur/store/root-reducer';

function isBrowser(state: RootState) {
  return state.browser.isBrowser;
}

function getBreakpoint(state: RootState) {
  return state.browser.breakpoint;
}

const breakpointUp = (name: Breakpoint) =>
  createSelector(getBreakpoint, (current) => {
    return breakpoints.indexOf(current) >= breakpoints.indexOf(name);
  });

const breakpointDown = (name: Breakpoint) =>
  createSelector(getBreakpoint, (current) => {
    return breakpoints.indexOf(current) <= breakpoints.indexOf(name);
  });

export const BrowserSelectors = {
  isBrowser,
  getBreakpoint,
  breakpointUp,
  breakpointDown,
} as const;
