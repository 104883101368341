import { useMatch } from '@reach/router';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { GatsbyLinkProps, Link } from 'gatsby';
import React, { FC, ReactNode, useEffect, useState, VFC } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { Button } from '@stur/components/core/button';
import { HamburgerButton } from '@stur/components/core/hamburger-button';
import { Icon } from '@stur/components/core/icon';
import { AnimationDuration } from '@stur/constants/animation.constant';
import { AuthActions } from '@stur/store/auth/auth-reducer';
import { AuthSelectors } from '@stur/store/auth/auth-selectors';
import { BrowserSelectors } from '@stur/store/browser/browser-selectors';
import { DOMUtils } from '@stur/utils/dom-utils';
import { RoutingUtils } from '@stur/utils/routing-utils';

import styles from './page-header.module.scss';

interface PageHeaderLinkProps extends GatsbyLinkProps<void> {
  children: ReactNode;
  keepParams?: string[];
}

const PageLink: FC<PageHeaderLinkProps> = ({ children, to, ref, ...linkProps }) => {
  const queryIndex = to.indexOf('?');
  const pagePath = queryIndex > 0 ? to.slice(0, queryIndex) : to;
  const isMatch = !!useMatch(pagePath);

  return (
    <span className={classNames({ 'is-active': isMatch })} ref={ref}>
      <Link to={to} {...linkProps}>
        {children}
      </Link>
    </span>
  );
};

export interface PageHeaderProps {}

export const PageHeader: VFC<PageHeaderProps> = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(AuthSelectors.isLoggedIn);
  const isAuthInitialized = useSelector(AuthSelectors.isInitialized);
  const isMobile = useSelector(BrowserSelectors.breakpointDown('small'));
  const isHomePage = !!useMatch(RoutingUtils.routes.logIn());
  const [isNavActive, setIsNavActive] = useState(false);
  const logoIcon = <Icon name="stur-logo" />;

  // close the mobile nav when switching to desktop
  useEffect(() => {
    if (!isMobile) {
      setIsNavActive(false);
    }
  }, [isMobile]);

  const getHomeRoute = () => {
    return isLoggedIn ? RoutingUtils.routes.dashboard() : RoutingUtils.routes.logIn();
  };

  const handleLogoutClick = () => {
    handleNavLinkClick();
    dispatch(AuthActions.logOut());
  };

  const handleNavToggleClick = () => {
    setIsNavActive(!isNavActive);
  };

  const handleNavLinkClick = () => {
    setIsNavActive(false);
  };

  const renderPublicLinks = () => {
    let returnPath: string | undefined;
    if (!DOMUtils.isSSR() && window.location.pathname.startsWith('/app')) {
      returnPath = window.location.pathname;
    }

    return (
      <>
        <li>
          <PageLink to={RoutingUtils.routes.createAccount(returnPath)} onClick={handleNavLinkClick}>
            Create Account
          </PageLink>
        </li>
        <li>
          <PageLink to={RoutingUtils.routes.logIn(returnPath)} onClick={handleNavLinkClick}>
            Log In
          </PageLink>
        </li>
      </>
    );
  };
  const renderUserLinks = () => (
    <>
      <li>
        <Button variant="link" onClick={handleLogoutClick}>
          Log Out
        </Button>
      </li>
    </>
  );
  const renderAboutLink = () => (
    <li>
      <a
        href="https://sturevents.com"
        target="_blank"
        rel="noreferrer"
        onClick={handleNavLinkClick}
      >
        About Stur
      </a>
    </li>
  );

  const renderMobileMenu = () => (
    <>
      <div className={classNames('menu-text', styles.menuText)}></div>
      <HamburgerButton
        className={styles.hamburgerButton}
        isActive={isNavActive}
        onClick={handleNavToggleClick}
      />
      <CSSTransition
        in={isNavActive}
        timeout={{
          enter: AnimationDuration.ENTER * 2 + 1000,
          exit: AnimationDuration.LEAVE,
        }}
        classNames={{
          enter: styles.overlayEnter,
          enterActive: styles.overlayEnterActive,
          exit: styles.overlayExit,
          exitActive: styles.overlayExitActive,
        }}
        unmountOnExit
      >
        <nav className={classNames(styles.nav, styles.mobileNav)}>
          <Helmet>
            {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
            <html data-reveal-open />
          </Helmet>
          <ul>
            {isLoggedIn ? renderUserLinks() : renderPublicLinks()}
            {renderAboutLink()}
          </ul>
        </nav>
      </CSSTransition>
    </>
  );

  const renderDesktopMenu = () => {
    const visuallyhidden = DOMUtils.isSSR();

    return (
      <>
        <div className={classNames('menu-text', styles.menuText, { visuallyhidden })}>
          <span className="show-for-large">Making it easier to organize events with friends.</span>
        </div>
        <nav className={classNames(styles.nav, styles.desktopNav, { visuallyhidden })}>
          <ul>
            {isLoggedIn ? renderUserLinks() : renderPublicLinks()}
            {renderAboutLink()}
          </ul>
        </nav>
      </>
    );
  };

  const renderMenu = () => {
    if (!isAuthInitialized) {
      return null;
    }
    return isMobile ? renderMobileMenu() : renderDesktopMenu();
  };

  return (
    <header id="page-header" className={styles.pageHeader}>
      <div className="grid-container">
        <FocusTrap active={isMobile && isNavActive}>
          <div className={classNames('menu', styles.menu)}>
            <div className={styles.logo}>
              {isHomePage ? (
                logoIcon
              ) : (
                <Link to={getHomeRoute()} aria-label="Home">
                  {logoIcon}
                </Link>
              )}
            </div>

            {renderMenu()}
          </div>
        </FocusTrap>
      </div>
    </header>
  );
};
