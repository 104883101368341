import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import { PageLoader } from '@stur/components/core/page-loader';

import styles from './page-content.module.scss';

export interface PageContentProps extends HTMLAttributes<HTMLElement> {
  isLoading?: boolean;
}

export const PageContent: FC<PageContentProps> = (props) => {
  const { children, className, isLoading, ...rest } = props;

  return (
    <main className={classNames(styles.main, { [styles.loading]: isLoading }, className)} {...rest}>
      <PageLoader isLoading={isLoading}>{children}</PageLoader>
    </main>
  );
};
