import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import { PageFooter } from '@stur/components/layout/page-footer';
import { PageHeader } from '@stur/components/layout/page-header';
import { PageMeta } from '@stur/components/layout/page-meta';

import styles from './page-layout.module.scss';

export interface PageLayoutProps extends HTMLAttributes<HTMLElement> {}

export const PageLayout: FC<PageLayoutProps> = ({ children, className, ...rest }) => {
  return (
    <div className={classNames(styles.pageLayout, className)} {...rest}>
      <PageMeta />

      <PageHeader />

      {children}

      <PageFooter />
    </div>
  );
};
